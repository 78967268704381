import { TagTheme } from '@/components/Tag/theme';
import { tw } from '@/utils/tw';
import BaseTrendingMenuTheme from 'base/components/TrendingMenu/theme';

const Tag = tw.theme({
  extend: TagTheme,
  defaultVariants: {
    size: 'medium',
  },
});

const TrendingMenu = tw.theme({
  extend: BaseTrendingMenuTheme,
  slots: {
    base: ['sm:my-4'],
    headline: ['text-body-sm', 'md:text-body-md'],
    prefix: ['text-logga-700'],
  },
});

export default Object.assign(TrendingMenu, { Tag });
