import { TagTheme } from '@/components/Tag/theme';
import { tw } from '@/utils/tw';

const Tag = tw.theme({
  extend: TagTheme,
  defaultVariants: {
    size: 'small',
  },
});

const TrendingMenu = tw.theme({
  slots: {
    base: [
      'relative',

      'flex',
      'flex-row',
      'flex-nowrap',
      'items-center',

      'my-3',
      'gap-3',
      'mx-grid-m',

      'overflow-y-visible',
      'overflow-x-scroll',
      'snap-x',
      'scrollbar-none',
    ],
    headline: ['text-black', 'text-headline-xs'],
    prefix: ['mr-1'],
  },
});

export default Object.assign(TrendingMenu, { Tag });
