import { merge } from '@/utils/merge';
import { tw } from '@/utils/tw';
import { default as BaseCoverBox } from 'base/components/CoverBox/theme';

const Prose = BaseCoverBox.Prose;

const CoverBox = tw.theme({
  extend: BaseCoverBox,
  slots: {
    base: ['bg-white', 'wings-white'],
    headline: ['text-black', 'text-headline-xs', 'sm:text-headline-sm', '!font-normal'],
    toggle: 'bg-white text-body-md sm:text-body-md',
  },
  variants: merge(BaseCoverBox.variants, {
    center: {
      true: {
        headline: `sm:text-headline-md`,
      },
    },
  }),
});

export default Object.assign(CoverBox, { Prose });
